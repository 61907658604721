<template>
<!-- 404页面 -->
  <div class="bg">
    <div class="system">
      <img src="../assets/img/system/404.png">
      <div class="title">
        <h2>网站维护中...</h2>
        <h4>为了让您更好的使用系统，我们正在对服务器进行升级，升级期间暂时无法访问。</h4>
        <h4>给您带来的不便，敬请谅解！</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.bg {
  line-height:1.6;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background: #7395ff !important;
}
.system{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  padding-top: 140px;
}
.system img{
  text-align: center;
  width: 500px;
}
.title{

}
.title h2{
  color: #fff;
  font-size: 36px;
  letter-spacing: 4px;
  text-align: center;
  line-height: 120px;
}
.title h4
{
  color: #fff;
  font-size: 18px;
  /* font-weight: bold; */
  line-height: 38px;
  text-align: center;
}

</style>
